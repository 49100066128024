import Axios from "axios";
import {
  GET_STATEMENTHEADER_REQUEST,
  GET_STATEMENTHEADER_SUCCESS,
  GET_STATEMENTHEADER_FAIL,
  POST_REQUESTNUM_REQUEST,
  POST_REQUESTNUM_SUCCESS,
  POST_REQUESTNUM_FAIL,
  GET_STATEMENTLINE_REQUEST,
  GET_STATEMENTLINE_SUCCESS,
  GET_STATEMENTLINE_FAIL,
  GET_STATEMENTEPDF_REQUEST,
  GET_STATEMENTPDF_SUCCESS,
  GET_STATEMENTPDF_FAIL,
} from "../constants/statementConstants";

const apiUrl = process.env.REACT_APP_API_URL;
const devApiUrl = process.env.REACT_APP_DEV_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getStatementHeader = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_STATEMENTHEADER_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/myAccount/statementHeader/${tokenUserId}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_STATEMENTHEADER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_STATEMENTHEADER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({
    type: GET_STATEMENTHEADER_SUCCESS,
    payload: data.data.result,
  });
};

export const postStatementRequestNum = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: POST_REQUESTNUM_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.post(
      `${apiUrl}/myAccount/statementHeader/requestNum/${tokenUserId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: POST_REQUESTNUM_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: POST_REQUESTNUM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({
    type: POST_REQUESTNUM_SUCCESS,
    payload: data.data.result,
  });

  return data;
};

export const getStatementLine = (statementId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_STATEMENTLINE_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/myAccount/statementLine/${statementId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
    console.log("statement response from action ", data);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_STATEMENTLINE_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_STATEMENTLINE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({
    type: GET_STATEMENTLINE_SUCCESS,
    payload: data.data.result,
  });
};

export const exportStatementPdf = (statementId) => async (dispatch) => {
  // Log the user ID and invoice ID for debugging purposes
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  let tokenLoginId = null;
  if (token) {
    tokenLoginId = token.result.user.loginId;
  }

  dispatch({ type: GET_STATEMENTEPDF_REQUEST, payload: tokenUserId });

  try {
    const response = await Axios.get(
      `${devApiUrl}/myAccount/statementLine/export/${tokenUserId}/${statementId}`,
      {
        // Include the authorization token in the request headers
        headers: { Authorization: `Bearer ${token.result.token}` },

        // Set the response type to 'blob' to handle binary data
        responseType: "blob",
      }
    );

    // If the response is not OK, throw an error
    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    // Create a URL for the blob object received in the response
    const url = window.URL.createObjectURL(new Blob([response.data]));

    // Create an anchor element to facilitate the download
    const a = document.createElement("a");
    a.style.display = "none"; // Hide the anchor element
    a.href = url; // Set the href attribute to the blob URL

    // Generate the current date string for file title
    const currentDate = new Date().toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
    a.download = `Statement_${currentDate}_${tokenLoginId}.pdf`; // Set the default download file name

    document.body.appendChild(a); // Append the anchor element to the document body
    a.click(); // Programmatically click the anchor to trigger the download
    window.URL.revokeObjectURL(url); // Revoke the blob URL to free up memory

    dispatch({
      type: GET_STATEMENTPDF_SUCCESS,
      payload: response.data.result,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_STATEMENTPDF_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_STATEMENTPDF_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }
};
