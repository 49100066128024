import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import InputCom from "../../../Helpers/InputCom";
import { checkout } from "../../../../actions/checkoutAction";
import PrimaryButton from "../../../Helpers/Buttons/PrimaryButton";

const StickyElement = ({
  isPoNumberVerified,
  userAccountInfo,
  cartItems,
  dropShip,
  isPreorder,
  shippingInfo,
  billingInfo,
  deliveryInfo,
  totalPrice,
  discountedTotalPrice,
  savedTotal,
  toggleCheckoutLoading,
}) => {
  const [couponCodeTmp, setCouponCodeTmp] = useState();
  const [couponCodeNullError, setCouponCodeNullError] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  const handleCouponCodeChange = (event) => {
    setCouponCodeTmp(event.target.value);
  };

  // Validation for coupon code
  const verifyCouponCode = async (code) => {
    if (!code) {
      setCouponCodeNullError("Please enter a PO Number");
      return;
    }
    // If coupon code exists, clear the error message
    setCouponCodeNullError("");

    // Call validation API forcoupon code
    console.log("discount code : ", code);
  };

  const handleProceedToCheckout = async () => {
    // If po number is not entered
    if (
      billingInfo.poNumber === null ||
      billingInfo.poNumber === undefined ||
      billingInfo.poNumber === ""
    ) {
      return alert("Please enter a PO Number");
    }

    // Check if poNumber validation is complete or not
    if (!isPoNumberVerified) {
      return alert("Please verify your PO Number before proceeding");
    } else {
    }

    // Check for empty or undefined shipping fields
    const requiredFields = [
      { value: billingInfo.erpAddressId, fieldName: "Billing Address ID" },
      { value: shippingInfo.erpAddressId, fieldName: "Shipping Address ID" },
      { value: shippingInfo.companyName, fieldName: "Name" },
      { value: shippingInfo.address1, fieldName: "Address1" },
      { value: shippingInfo.city, fieldName: "City" },
      { value: shippingInfo.countryId, fieldName: "Country" },
    ];

    // Accumulate the names of empty shipping fields
    const emptyFields = requiredFields
      .filter((field) => !field.value && !dropShip) // Skip check if IsDropShip is true
      .map((field) => field.fieldName);

    console.log("requiredFields : ", requiredFields);

    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.join(", ");
      return alert(
        `It looks like your shipping information isn’t correct.\nPlease get in touch with our customer service team for assistance.\n\nEmpty fields : ${fieldNames}.\n`
        // `It looks like your shipping or billing address information isn’t correct. Please get in touch with our customer service team for assistance.`
      );
    }

    // Turn on loading spinner
    toggleCheckoutLoading(true);

    // Construct the orderInfo object
    const orderInfo = {
      UserId: tokenUserId,
      // Accouncting
      erpCustomerId: userAccountInfo.erpCustomerId,
      erpPaymentTermId: userAccountInfo.erpPaymentTermId,
      accountName: userAccountInfo.accountName,
      accountNumber: userAccountInfo.accountNumber,
      contactPhone: userAccountInfo.contactPhone,
      contactEmail: userAccountInfo.contactEmail,
      ErpBillToAddressId: billingInfo.erpAddressId,
      // Shipping address
      ErpShipToAddressId: shippingInfo.erpAddressId,
      ShipToName: shippingInfo.companyName,
      ShipToAddress1: shippingInfo.address1,
      ShipToAddress2: shippingInfo.address2,
      ShipToCity: shippingInfo.city,
      ShipToStateId: shippingInfo.stateId,
      ShipToCountryId: shippingInfo.countryId,
      ShipToZipcode: shippingInfo.zipCode,
      RequestedShipDate: deliveryInfo.requestedDate,
      IsDropShip: dropShip,
      // Delivery
      SignatureService: deliveryInfo.signatureService,
      Note: deliveryInfo.memo,
      ErpCarrierId: deliveryInfo.erpCarrierId,
      ErpCarrierServiceId: deliveryInfo.erpServiceId,
      // Item
      IsPreorder: isPreorder,
      CustomerPO: billingInfo.poNumber,
      orderLine: cartItems.map((item) => ({
        Pid: item.pid,
        Qty: item.qty,
      })),
    };

    console.log("orderInfo", orderInfo);

    dispatch(checkout(orderInfo))
      .then((response) => {
        if (response.data.statusCode === 200) {
          // Turn off loading spinner on success
          toggleCheckoutLoading(false);

          // Alert for order success
          alert(
            "Your order is successfully submitted. You will be receiving a packing list with your tracking number within 72 hours."
          );

          // Move to order confirmation page and send the "ponumber" as a parameter when calling /orderconfirm.
          navigate(`/orderconfirm/${orderInfo.CustomerPO}`);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        if (error.response.data.statusCode === 200) {
          // Turn off loading spinner on error
          toggleCheckoutLoading(false);

          alert("Your order was successful! (error) ");
        } else if (error.response.data.statusCode === 400) {
          alert(
            "Your PO number is duplicated. Please enter your PO number again."
          );
          // Handle the 400 error as needed
        } else if (error.response.data.statusCode === 500) {
          alert(
            "Please check your items you have been puchasing. Or something went wrong with your order confirmation. Please contact to our customer service team."
          );
          // Handle the 500 error as needed
        } else {
          alert(
            "An unexpected error occurred. Please contact to our customer service team."
          );
          // Handle other errors as needed
        }

        navigate(`/cart`);
      });
  };

  return (
    <div className="price-container sticky w-full">
      <div className="price-tax-info p-4">
        {/* Price and tax info */}
        <div className="border-b pb-3 mb-3">
          <h3 className="font-bold text-lg">Order Summary</h3>
        </div>
        <div className="flex text-sm justify-between mb-2">
          <span>Items:</span>
          {/* <span>${totalPrice.toFixed(2)}</span> */}
          <span>${(totalPrice || 0).toFixed(2)}</span>
        </div>
        <div className="flex text-sm justify-between mb-2">
          <span>Dropship fee :</span>
          <span>{dropShip === 1 ? "+$2.00" : "-"}</span>
        </div>
        <div className="flex text-sm justify-between mb-2">
          <span>Estimated Shipping & Handling:</span> <br />
        </div>
        <div
          className="flex justify-between mb-2"
          style={{ fontSize: "0.75rem", color: "#7C7C7C" }}
        >
          <span>Check invoice</span>
        </div>
        <div className="flex text-sm justify-between mb-2">
          <span>Estimated Tax:</span>
        </div>
        <div
          className="flex justify-between mb-2"
          style={{ fontSize: "0.75rem", color: "#7C7C7C" }}
        >
          <span>Check invoice</span>
        </div>
        {/*   <div className="flex text-sm justify-between mb-2">
          <span>You saved:</span>
           <span> - ${savedTotal.toFixed(2)}</span>  
          <span> - ${(savedTotal || 0).toFixed(2)}</span>
        </div> */}
        <div>
          <br />
        </div>
        <div
          className="border-t pt-3 flex justify-between font-bold"
          style={{ color: "#FF4444" }}
        >
          <span>Order Total:</span>
          {/*<span>
            $
            {(dropShip === 1
              ? discountedTotalPrice + 2
              : discountedTotalPrice
            ).toFixed(2)}
          </span> */}
          <span>
            $
            {(dropShip === 1
              ? (discountedTotalPrice || 0) + 2
              : discountedTotalPrice || 0
            ).toFixed(2)}
          </span>
        </div>
      </div>

      {/*
      <div className="px-4 pb-4">
        <button className="amazon-button" onClick={() => navigate("/cart")}>
          Go to Cart
        </button>
      </div>
     */}

      <div className="button-container">
        <PrimaryButton
          width="227px"
          height="40px"
          fontSize="16px"
          onClick={handleProceedToCheckout}
        >
          Place your order
        </PrimaryButton>
      </div>

      <p className="px-4 pb-4 text-sm">
        You can find the detailed shipping costs and tax prices on your invoice.
        The actual invoice will also show the discount you received with your
        promo code.
      </p>

      <p className="px-4 pb-4 text-sm">
        By placing your order, you agree to Maevn Uniform's privacy notice and
        conditions of use.
      </p>

      {/* Online coupon */}
      {/*<div className="px-4">
        <div className="discount-code sm:w-full w-full mb-5 sm:mb-0 h-[50px] flex">
          <div className="flex-1 h-full">
            <InputCom
              type="text"
              placeholder={
                couponCodeNullError ? "Please enter a code" : "Discount code"
              }
              value={couponCodeTmp}
              inputHandler={handleCouponCodeChange}
              className={couponCodeNullError ? "warning" : ""}
            />
          </div>
          <button
            type="button"
            className="w-[70px] h-[50px] black-btn"
            onClick={() => verifyCouponCode(couponCodeTmp)}
          >
            <span className="text-sm font-semibold">Apply</span>
          </button>
        </div>
            </div> */}
    </div>
  );
};

export default StickyElement;
