import { useLocation } from "react-router-dom";
import "../Orders/OrderHistory.css";

function decodeHTMLEntities(text) {
  var txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
}

export default function OrderInfo({
  className,
  isLoading,
  loginId,
  poNumber,
  orderStatus,
  orderId,
  dropship,
  carrier,
  carrierService,
  requestedShipDate,
  signatureService,
  note,
  email,
  totalPrice,
  discountedTotalPrice,
  shipToAddress,
  billToAddress,
  itemList,
}) {
  function formatCurrentDate() {
    const date = new Date();
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  function getStatusDescription(statusCode) {
    switch (statusCode) {
      case 0:
        return "Open";
      case 10:
        return "issued";
      case 30:
        return "Processing";
      case 70:
        return "Shipped";
      case 80:
        return "Partially Shipped";
      case 90:
        return "Canceled";
      case 95:
        return "Cancellation request";
      default:
        return "Unknown Status";
    }
  }

  return (
    <div
      className={`w-full ${className || ""} `}
      style={{ fontFamily: "Arial, sans-serif" }}
    >
      <div className="flex-1  mt-8  ">
        <h1 className="thank-you-message">
          <strong> Thank you for your purchase! </strong>
        </h1>
        <h1 className="confirmation-email">
          A confirmation email has been sent to
          <span className="confirmation-email-text"> {email}</span>.
        </h1>
      </div>
      <div>
        {/* Order details */}
        <div className="order-details-container">
          <h2 className="order-details-text">Order Details</h2>
        </div>
      </div>
      <div className="details-container">
        <div className="detail-item">
          <p className="detail-item-title-text">Ordered by</p>
          <p className="detail-item-detail-text">{loginId}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">PO number</p>
          <p className="detail-item-detail-text">{poNumber}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">Order placed</p>
          <p className="detail-item-detail-text">{formatCurrentDate()}</p>
        </div>
        <div className="detail-item">
          <p className="detail-item-title-text">Order Status</p>
          <p className="detail-item-detail-text">
            {/*  {getStatusDescription(orderStatus)}   */}
            Opened
          </p>
        </div>
      </div>

      {/* delivery service */}
      <div className="delivery-service-container">
        <div className="delivery-service-block">
          <div className="Request-delivery-service-title">
            Request delivery service
          </div>
          <div className="delivery-service-detail">
            <p className="carrier-text">
              Carrier:{" "}
              <span className="carrier-service-text">
                {carrier ? carrier : " - "}
              </span>
            </p>
            <p className="carrier-text">
              Carrier Service:{" "}
              <span className="carrier-service-text">
                {carrierService ? carrierService : " - "}
              </span>
            </p>
          </div>
        </div>
        <div className="delivery-service-block">
          <div className="Request-delivery-service-title">
            Optional delivery service
          </div>
          <div className="delivery-service-detail">
            <p className="carrier-text">
              Requested Ship Date:{" "}
              <span className="carrier-service-text">
                {requestedShipDate ? requestedShipDate : " - "}
              </span>
            </p>
            <p className="carrier-text">
              Note:{" "}
              <span className="carrier-service-text">
                {note ? note : " - "}
              </span>
            </p>{" "}
            <p className="carrier-text">
              Signature Service:{" "}
              <span className="carrier-service-text">
                {signatureService === 0
                  ? " - "
                  : signatureService
                  ? "YES"
                  : " - "}
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Addresses*/}
      <div className="flex space-x-10">
        {/* shipping */}
        <div>
          <div className="order-address-header">
            <div className="order-text-container">
              <p className="order-header-text ">
                Shipping address{" "}
                {dropship === 1 && (
                  <span className="order-detail-drop-ship">(DropShip)</span>
                )}{" "}
              </p>
            </div>
          </div>

          <div className="order-address-details">
            <div className="order-details-text">
              <p className="order-recipient-name">
                {" "}
                {shipToAddress?.companyName || "Not Provided"}
              </p>
              <br />
              <p className="order-recipient-address">
                {shipToAddress?.address1 || ""},{" "}
                {shipToAddress?.address2 ? shipToAddress?.address2 + "," : ""}{" "}
                <br /> <br />
                {shipToAddress?.stateName || ""}, {shipToAddress?.zipCode || ""}{" "}
                {shipToAddress?.countryName || ""}
              </p>
            </div>
          </div>
        </div>
        {/* billing */}
        <div>
          <div className="order-address-header">
            <div className="order-text-container">
              <p className="order-header-text "> Billing address </p>
            </div>
          </div>
          <div className="order-address-details">
            <div className="order-details-text">
              <p className="order-recipient-name">
                {" "}
                {billToAddress?.companyName || "Not Provided"}
              </p>
              <br />
              <p className="order-recipient-address">
                {billToAddress?.address1 || ""},{" "}
                {billToAddress?.address2 ? billToAddress?.address2 + "," : ""}{" "}
                <br /> <br />
                {billToAddress?.stateName || ""}, {billToAddress?.zipCode || ""}{" "}
                {billToAddress?.countryName || ""}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* items */}
      <div className="w-full px-10 py-[30px] border border-[#EDEDED]  mt-10">
        <div className="sub-total mb-6">
          <div className=" flex justify-between mb-5">
            <p className="text-[13px] font-medium text-qblack uppercase">
              Product
            </p>

            <p className="text-[13px] font-medium text-qblack uppercase">
              Sub total
            </p>
          </div>
          <div className="w-full h-[1px] bg-[#EDEDED]"></div>
        </div>

        {itemList &&
          itemList.map((item, index) => {
            {
              /* decodeHTMLEntities */
            }
            const decodedDescription = decodeHTMLEntities(
              item.shortDescription
            );

            return (
              <div className="product-list w-full mb-[30px]" key={index}>
                <ul className="flex flex-col space-y-5">
                  <li>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <img
                          src={`https://maevn-images.s3.us-east-2.amazonaws.com/110x110/${item.productLine.replace(
                            /\s+/g,
                            ""
                          )}/${item.styleNumber}${item.colorCode}.jpg`}
                          alt="No Image"
                          style={{ width: "60px", height: "60px" }}
                        />
                        <div>
                          <h4 className="text-[15px] text-qblack mb-2.5">
                            {decodedDescription}
                            <sup className="text-[13px] text-qgray ml-2 mt-2">
                              x {item.qty}
                            </sup>
                          </h4>
                          <p className="text-[13px] text-qgray">
                            Style no:{"  "} {item.styleNumber}, Color:{"  "}{" "}
                            {item.color}, Fit:{"  "}
                            {item.fit}, Size:{"  "}
                            {item.size}
                          </p>
                        </div>
                      </div>
                      <div>
                        <span className="text-[15px] text-qblack font-medium">
                          $
                          {item.discountRate > 0
                            ? item.discountedSubTotal
                            : item.subTotal}
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            );
          })}

        <div className="w-full h-[1px] bg-[#EDEDED]"></div>

        <div className="mt-[30px]">
          <div className=" flex justify-between mb-5">
            <p className="text-2xl font-medium text-qblack">Total</p>
            <p className="text-2xl font-medium text-qred">
              $
              {discountedTotalPrice < totalPrice
                ? discountedTotalPrice
                : totalPrice}
            </p>
          </div>
        </div>
        <div>
          <p
            className="text-[15px] text-yellow-500 "
            style={{
              textAlign: "left",
              marginTop: "25px",
              marginBottom: "15px",
              color: "#FF4444",
            }}
          >
            ** Note : You can find the detailed shipping costs and tax prices on
            your invoice. <br /> &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;The actual invoice
            will also show the discount you received with your promo code.
          </p>
        </div>
      </div>

      <div className="flex justify-end ">
        <div
          style={{
            marginBottom: "150px",
          }}
        ></div>
      </div>
    </div>
  );
}
