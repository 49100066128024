import Axios from "axios";
import {
  GET_INVOICEHEADER_REQUEST,
  GET_INVOICEHEADER_SUCCESS,
  GET_INVOICEHEADER_FAIL,
  GET_INVOICELINE_REQUEST,
  GET_INVOICELINE_FAIL,
  GET_INVOICELINE_SUCCESS,
  SET_SELECTEDINVOICE_REQUEST,
  SET_SELECTEDINVOICE_FAIL,
  SET_SELECTEDINVOICE_SUCCESS,
  GET_INVOICEPDF_REQUEST,
  GET_INVOICEPDF_FAIL,
  GET_INVOICEPDF_SUCCESS,
} from "../constants/invoiceConstants";

const apiUrl = process.env.REACT_APP_API_URL;
const devApiUrl = process.env.REACT_APP_DEV_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const getInvoiceHeader = () => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_INVOICEHEADER_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(`${apiUrl}/myAccount/invoiceHeader/${tokenUserId}`, {
      headers: { Authorization: `Bearer ${token.result.token}` },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_INVOICEHEADER_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_INVOICEHEADER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({
    type: GET_INVOICEHEADER_SUCCESS,
    payload: data.data.result,
  });
};

export const getInvoiceLine = (erpInvoiceId) => async (dispatch) => {
  // if there is no token, go to login page
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  let tokenUserId = null;
  if (token) {
    tokenUserId = JSON.parse(token.result.user.userId);
  }

  // redirect to login page and return
  if (!token) {
    console.log("no token", token);
    return;
  }

  dispatch({ type: GET_INVOICELINE_REQUEST, payload: tokenUserId });

  let { data } = "";
  try {
    data = await Axios.get(
      `${apiUrl}/myAccount/invoiceLine/${tokenUserId}/${erpInvoiceId}`,
      {
        headers: { Authorization: `Bearer ${token.result.token}` },
      }
    );
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: GET_INVOICELINE_FAIL,
        payload: error.response.data.error,
      });
    } else {
      dispatch({
        type: GET_INVOICELINE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
    return;
  }
  dispatch({
    type: GET_INVOICELINE_SUCCESS,
    payload: data.data.result,
  });
};

export const exportInvoicePdf =
  (erpInvoiceId, invoiceNum) => async (dispatch) => {
    // Log the user ID and invoice ID for debugging purposes
    const token = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    let tokenUserId = null;
    if (token) {
      tokenUserId = JSON.parse(token.result.user.userId);
    }

    dispatch({ type: GET_INVOICEPDF_REQUEST, payload: tokenUserId });

    try {
      const response = await Axios.get(
        `${devApiUrl}/myAccount/invoiceLine/export/${tokenUserId}/${erpInvoiceId}`,
        {
          // Include the authorization token in the request headers
          headers: { Authorization: `Bearer ${token.result.token}` },

          // Set the response type to 'blob' to handle binary data
          responseType: "blob",
        }
      );

      // If the response is not OK, throw an error
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      // Create a URL for the blob object received in the response
      const url = window.URL.createObjectURL(new Blob([response.data]));
      // Create an anchor element to facilitate the download
      const a = document.createElement("a");
      a.style.display = "none"; // Hide the anchor element
      a.href = url; // Set the href attribute to the blob URL
      a.download = `${invoiceNum}.pdf`; // Set the default download file name
      document.body.appendChild(a); // Append the anchor element to the document body
      a.click(); // Programmatically click the anchor to trigger the download
      window.URL.revokeObjectURL(url); // Revoke the blob URL to free up memory

      dispatch({
        type: GET_INVOICEPDF_SUCCESS,
        payload: response.data.result,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: GET_INVOICEPDF_FAIL,
          payload: error.response.data.error,
        });
      } else {
        dispatch({
          type: GET_INVOICEPDF_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    }
  };
