import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./QuickOrder.css";
import {
  getCollections,
  getFilteredInventory,
  getInitialInventory,
  getStyles,
} from "../../actions/quickorderAction";
import { getCart, updateCartQuickOrder } from "../../actions/cartAction";
import { GET_QUICKORDER_RESET } from "../../constants/quickorderConstants";
import PrimaryButton from "../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../Helpers/Buttons/SecondaryButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import styled from "@emotion/styled";

function decodeHTMLEntities(text) {
  var txt = document.createElement("textarea");
  txt.innerHTML = text;
  return txt.value;
}

const QuickOrderForm = ({ userId, show, onClose }) => {
  // validate user
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation(); // search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // call collections API
  const collectionInfo = useSelector((state) => state.getCollections);
  const { collectionLoading, collectionError, collections } = collectionInfo;

  // call styles API
  const styleInfo = useSelector((state) => state.getStyles);
  const { styleLoading, styleError, styles } = styleInfo;

  // call inventory API
  const inventoryInfo = useSelector((state) => state.getInitialInventory);
  const {
    initialLoading,
    initialError,
    inventory: initialInventory,
  } = inventoryInfo;

  // Show collection dropdown
  const [showCollectionDropdown, setShowCollectionDropdown] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [modalHeight, setModalHeight] = useState("40vh"); // initial height of modal-quickorder-content
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Show style dropdown
  const [showStyleDropdown, setShowStyleDropdown] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState("");
  const [selectedStylePlaceholder, setSelectedStylePlaceholder] =
    useState("Search Product");
  const [selectedStyleNumber, setSelectedStyleNumber] = useState("");

  // Add new states for selected values for color, fit, inseam
  const [selectedColor, setSelectedColor] = useState("--Select--");
  const [selectedFit, setSelectedFit] = useState(null);

  // For extendable filter container
  const modalContentRef = useRef(null);
  const filterContainerRef = useRef(null);
  const handleResize = () => {
    if (modalContentRef.current && filterContainerRef.current) {
      // console.log( "modalContentRef Setting width:", modalContentRef.current.scrollWidth );
      filterContainerRef.current.style.width = `${modalContentRef.current.scrollWidth}px`;
    }
  };

  useEffect(() => {
    handleResize();
  }, [initialInventory, selectedStyleNumber]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //  Function called when the save button is clickable
  const [orderItems, setOrderItems] = useState([]);

  // Function called each time a value is entered into the input box to update the state
  const handleInputChange = (pid, value, qtyAvailable) => {
    const parsedValue = parseInt(value, 10);

    if (parsedValue === 0) {
      alert("The minimum order quantity is 1 or more.");
      return;
    }

    if (parsedValue > qtyAvailable) {
      alert(
        "You can place backorders for any remaining quantities that exceed the current orderable quantity. If you prefer not to place a backorder, kindly adjust the quantity of the item in your order to match the currently available stock."
      );
    }

    const newOrderItems = [...orderItems];
    const index = newOrderItems.findIndex((item) => item.pid === pid);

    const userId = userInfo.result.user.userId;
    if (index === -1) {
      newOrderItems.push({ pid, qty: parsedValue, userId });
      //newOrderItems.push({ pid, qty: parseInt(value, 10) });
    } else {
      newOrderItems[index].qty = parseInt(value, 10);
    }

    setOrderItems(newOrderItems);
  };

  // Check if initialInventory is populated and set initial selectedFit
  useEffect(() => {
    if (
      initialInventory &&
      initialInventory.colors &&
      initialInventory.colors.length > 0
    ) {
      setOrderItems([]);
    }
  }, [initialInventory]);

  // call getCollections API
  const handleCollectionInputClick = async () => {
    setShowCollectionDropdown(true);
    dispatch(getCollections());

    // for place holder of style input box
    setSelectedStyle("");
    setSelectedStylePlaceholder("Search Product");
  };

  // call getStyles API
  const handleCollectionClick = (programId, productLine) => {
    setSelectedCollection(productLine);
    setShowCollectionDropdown(false);

    dispatch({ type: GET_QUICKORDER_RESET });
    dispatch(getStyles(programId));
  };

  // Show style dropdown
  const handleStyleInputClick = async () => {
    setShowStyleDropdown(true);
  };

  // Click one of styles
  const handleStyleClick = (stylenumber, shortdescription) => {
    setSelectedStyle(shortdescription);
    setShowStyleDropdown(false);
    setSelectedStyleNumber(stylenumber);

    // Every inventory quantity is shown for the style initially
    setSelectedColor("--Select--");
  };

  // call getInventory API
  useEffect(() => {
    dispatch(getInitialInventory(selectedStyleNumber));
  }, [dispatch, selectedStyleNumber]);

  // Add event handlers for new dropdowns
  const handleColorChange = (event) => {
    const value = event.target.value;
    setSelectedColor(value === "" ? null : value);
  };

  const filteredColors =
    selectedColor === "--Select--"
      ? initialInventory?.colors
      : initialInventory?.colors.filter(
          (color) => color.colorCode === selectedColor
        );

  // Sort itemSize
  const sizeOrder = [
    "XXS",
    "XS",
    "SM",
    "MD",
    "LG",
    "XL",
    "1XL",
    "2XL",
    "3XL",
    "4XL",
    "5XL",
    "XXSP",
    "XSP",
    "SMP",
    "MDP",
    "LGP",
    "XLP",
    "2XLP",
    "3XLP",
    "XXST",
    "XST",
    "SMT",
    "MDT",
    "LGT",
    "XLT",
    "2XLT",
    "3XLT",
    "XXSS",
    "XSS",
    "SMS",
    "MDS",
    "LGS",
    "XLS",
    "2XLS",
    "3XLS",
  ];

  const fitOrder = [
    "Reg",
    "Plus",
    "Petite",
    "Petite Plus",
    "Tall",
    "Tall Plus",
    "Short",
    "Short Plus",
  ];

  const getAllSizes = () => {
    const allSizes = new Set();
    if (initialInventory && initialInventory.colors) {
      initialInventory.colors.forEach((color) => {
        color.fits.forEach((fit) => {
          fit.items.forEach((item) => {
            allSizes.add(item.itemSize);
          });
        });
      });
    }
    return Array.from(allSizes).sort(
      (a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)
    );
  };

  const getFilteredSizes = () => {
    const filteredSizes = new Set();
    if (filteredColors) {
      filteredColors.forEach((color) => {
        color.fits.forEach((fit) => {
          fit.items.forEach((item) => {
            filteredSizes.add(item.itemSize);
          });
        });
      });
    }
    return Array.from(filteredSizes).sort(
      (a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)
    );
  };

  const sizesToDisplay =
    selectedColor === "--Select--" ? getAllSizes() : getFilteredSizes();

  console.log("sizesToDisplay", sizesToDisplay);

  const handleKeepShopping = () => {
    navigate("/");
  };

  const handleOrderClick = async () => {
    const hasValidQuantity = orderItems.some(
      (item) => !item.quantity || item.quantity === 0
    );
    if (!hasValidQuantity) {
      alert("Please enter the quantity you would like to order.");
      return;
    }
    try {
      await dispatch(updateCartQuickOrder(orderItems));
      dispatch(getCart(userId));
      setIsModalOpen(true);
      setOrderItems([]);
      setSelectedCollection("");
      setSelectedStyle("");
      setShowCollectionDropdown(false);
      setShowStyleDropdown(false);
      setModalHeight(`${40}vh`);
      setSelectedStyleNumber("");
      dispatch({ type: GET_QUICKORDER_RESET });
    } catch (error) {
      alert("An error occurred while adding items to the cart.");
      console.error("Error adding to cart:", error);
    }
  };

  // move to cart or stay here
  const handleStayHereClick = () => {
    setIsModalOpen(false);
  };

  const handleGoToCartClick = () => {
    setIsModalOpen(false);

    // close modal
    onClose(true);

    navigate(`/quickorder`);
  };

  // expandable view for the # of rows
  useEffect(() => {
    if (filteredColors) {
      // Check for null or undefined
      // console.log("filteredColors", filteredColors);
      // console.log("filteredColors.length, ", filteredColors.length);
      // console.log("modalHeight, ", modalHeight);

      // Each color takes up 50px, with an additional 100px for headers and buttons, etc.
      const calculatedHeight = 60 * filteredColors.length + 100;

      // Minimum 300px, maximum 900px, converted to vh units
      // const vh = Math.min(Math.max(calculatedHeight, 500), 900) * 0.1;

      const vh = Math.min(Math.max(calculatedHeight, 900), 900) * 0.1;

      setModalHeight(`${vh}vh`);
    }
  }, [filteredColors]);

  // For extendable filter container
  useEffect(() => {
    if (filterContainerRef.current) {
      const totalWidth = sizesToDisplay.length * 100; // Assuming each size column needs 80px width
      filterContainerRef.current.style.width = `${totalWidth}px`;
    }
  }, [sizesToDisplay]);

  // Styled Dialog Content
  const StyledDialogContent = styled(DialogContent)``;

  // Styled Dialog Actions
  const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
  `;

  // Styled Dialog with Paper Props
  const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      padding: 30px;
      border-radius: 10px;
      background: var(--Base-White, #fff);
    }
  `;

  return (
    show && (
      <div className="modal-quickorder-backdrop">
        <div
          className="modal-quickorder-content"
          ref={modalContentRef}
          style={{ height: modalHeight }}
        >
          <div className="modal-quickorder-header">QUICK ORDER PAD</div>
          <div className="modal-quickorder-body">
            <div
              className="close-quickorder-button"
              onClick={() => {
                setSelectedCollection("");
                setSelectedStyle("");
                setSelectedStyleNumber("");
                setShowCollectionDropdown(false);
                setShowStyleDropdown(false);
                setModalHeight(`${40}vh`);
                dispatch({ type: GET_QUICKORDER_RESET });
                onClose();
              }}
            >
              X
            </div>
            <div className="input-quickorder-container">
              <div className="collection-dropdown-container">
                <input
                  type="text"
                  value={selectedCollection}
                  placeholder="Search Category"
                  className="search-quickorder-input"
                  onClick={handleCollectionInputClick}
                />
                {showCollectionDropdown && (
                  <div className="collection-dropdown">
                    {collections &&
                      collections.data &&
                      collections.data.result &&
                      collections.data.result.map((collection) => (
                        <div
                          key={collection.erpProgramId}
                          className="collection-item"
                          onClick={() =>
                            handleCollectionClick(
                              collection.erpProgramId,
                              collection.productLine
                            )
                          }
                        >
                          {collection.productLine}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <div className="collection-dropdown-container">
                <input
                  type="text"
                  value={selectedStyle}
                  placeholder="Search Product"
                  className="search-quickorder-input"
                  onClick={handleStyleInputClick}
                />
                {showStyleDropdown && (
                  <div className="collection-dropdown">
                    {styles &&
                      styles.data &&
                      styles.data.result &&
                      styles.data.result.map((style) => (
                        <div
                          key={style.styleNumber}
                          className="collection-item"
                          onClick={() =>
                            handleStyleClick(
                              style.styleNumber,
                              style.shortDescription
                            )
                          }
                        >
                          {style.styleNumber} ({style.shortDescription})
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {selectedStyleNumber && (
              <div
                className="filters-dropdown-container"
                ref={filterContainerRef}
              >
                <label htmlFor="colorDropdown">Color</label>
                <select value={selectedColor} onChange={handleColorChange}>
                  <option value="--Select--">--Select--</option>
                  {initialInventory &&
                    initialInventory.colors.map((color) => (
                      <option key={color.colorCode} value={color.colorCode}>
                        {color.colorName}
                      </option>
                    ))}
                </select>
              </div>
            )}

            {selectedStyleNumber && (
              <div className="inventory-display-container">
                <table className="inventory-table">
                  <thead>
                    <tr>
                      <th className="inventory-th"></th>
                      {sizesToDisplay.map((size) => (
                        <th key={size}>{size}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredColors?.map((color) => (
                      <tr key={color.colorCode}>
                        <td className="inventory-td">
                          <div className="color-info">
                            <img
                              src={`https://maevn-images.s3.us-east-2.amazonaws.com/swatches/${color.colorCode.toLowerCase()}.jpg`}
                              alt="Color Sample"
                              className="color-sample-image-quickorder"
                            />
                            <span className="color-name">
                              {color.colorCode} - {color.colorName}
                            </span>
                          </div>
                        </td>
                        {sizesToDisplay.map((size) => {
                          const item = color.fits // Sort fits
                            .sort(
                              (a, b) =>
                                fitOrder.indexOf(a.fitName) -
                                fitOrder.indexOf(b.fitName)
                            )
                            .flatMap((fit) => fit.items)
                            .find((item) => item.itemSize === size);
                          const isAvailable =
                            item &&
                            item.item.qtyAvailable > 0 &&
                            item.item.nextAvailDate !== null;
                          const isUnavailable =
                            item &&
                            item.item.qtyAvailable <= 0 &&
                            item.item.nextAvailDate === null;
                          return (
                            <td className="inventory-td" key={size}>
                              <input
                                type="text"
                                placeholder={
                                  isUnavailable
                                    ? "n/a"
                                    : item
                                    ? item.item.qtyAvailable.toString()
                                    : "n/a"
                                }
                                readOnly={isUnavailable}
                                onChange={(e) =>
                                  handleInputChange(
                                    item ? item.item.pid : null,
                                    e.target.value,
                                    item ? item.item.qtyAvailable : null
                                  )
                                }
                                style={{
                                  border: "1px solid #f0f0f0",
                                  width: "50px",
                                  height: "25px",
                                  fontSize: "12px",
                                  paddingLeft: "5px",
                                }}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {selectedStyleNumber && (
              <div
                className="save-button-container"
                style={{ display: "flex", marginTop: "30px" }}
              >
                <PrimaryButton
                  style={{ width: "150px" }}
                  onClick={handleOrderClick}
                >
                  Add To Cart
                </PrimaryButton>
              </div>
            )}

            <Dialog
              open={isModalOpen}
              onClose={handleStayHereClick}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xs" // Limit the width of the dialog
              fullWidth
            >
              {/* <StyledDialogTitle id="alert-dialog-title">
                  Alert
                  </StyledDialogTitle> */}
              <StyledDialogContent
                style={{ paddingTop: "0", paddingBottom: "0" }}
              >
                <DialogContentText
                  id="alert-dialog-description"
                  style={{ textAlign: "center", lineHeight: "80px" }}
                >
                  A new item has been added to your shopping Cart.
                </DialogContentText>
              </StyledDialogContent>

              <StyledDialogActions
                style={{ marginTop: "0", justifyContent: "flex-end" }}
              >
                {/* <button
                      type="button"
                      className="white-btn text-sm font-semibold w-1/4 h-10 bg-white text-black"
                      style={{ border: "1px solid black" }}
                      onClick={handleStayHereClick}
                    >
                      Keep Shopping
                    </button> */}
                <SecondaryButton
                  width="180px"
                  height="40px"
                  fontSize="16px"
                  onClick={handleStayHereClick}
                  // disabled={disabled}
                >
                  Keep Shopping
                </SecondaryButton>
                {/* <button
                      type="button"
                      className="black-btn text-sm font-semibold w-1/4 h-10 bg-black text-white"
                      onClick={handleGoToCartClick}
                    >
                      Go To Cart
                     </button> */}

                <PrimaryButton
                  width="180px"
                  height="40px"
                  fontSize="16px"
                  onClick={handleGoToCartClick}
                  // disabled={disabled}
                >
                  Go To Cart
                </PrimaryButton>
              </StyledDialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    )
  );
};
export default QuickOrderForm;
