import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";
// Import SVGs or define SVG paths
import PlayIcon from "../../images/play-icon.svg";
import PauseIcon from "../../images/pause-icon.svg";

const SubBanners = () => {
  const subBannerImages = [
    {
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/banners/2024-01/s1/Homepage_s1_Eon_50off_04.jpg",
      navigateTo: "/Promotions/SubBanner/EON",
    },
    {
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/banners/2024-01/s1/Homepage_s1_Blossom_Signature_25off_03.jpg",
      navigateTo: "/Collection/BlossomSignature",
    },
    {
      url: "https://maevn-images.s3.us-east-2.amazonaws.com/banners/2024-01/s1/Homepage_s1_Blossom_30off_06.jpg",
      navigateTo: "/Collection/Blossom",
    },
  ];

  const navigate = useNavigate();

  const sliderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        type="button"
        className="sub-slick-next-arrow sub-slick-arrow"
        onClick={onClick}
      >
        <div className="aboutus-button-content">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 56 56"
            fill="none"
          >
            <circle cx="28" cy="28" r="28" fill="rgba(255, 255, 255, 0.6)" />
            <foreignObject x="20" y="20" width="32" height="32">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 9 9"
                fill="none"
              >
                <path
                  d="M8.07287 4.22741L1.70836 0.228985C1.60217 0.162236 1.46108 0.125 1.31436 0.125C1.16764 0.125 1.02656 0.162236 0.920363 0.228985L0.91351 0.233503C0.86185 0.265862 0.820715 0.304812 0.792605 0.347984C0.764496 0.391156 0.750001 0.437647 0.750001 0.484629C0.750001 0.531611 0.764496 0.578102 0.792605 0.621274C0.820715 0.664445 0.86185 0.703395 0.91351 0.735754L6.90686 4.50075L0.913509 8.26424C0.86185 8.2966 0.820715 8.33555 0.792605 8.37873C0.764495 8.4219 0.75 8.46839 0.75 8.51537C0.75 8.56235 0.764495 8.60884 0.792605 8.65201C0.820715 8.69519 0.86185 8.73414 0.913509 8.7665L0.920362 8.77101C1.02656 8.83776 1.16764 8.875 1.31436 8.875C1.46108 8.875 1.60217 8.83776 1.70836 8.77101L8.07287 4.77259C8.12884 4.73742 8.17341 4.69513 8.20385 4.64828C8.2343 4.60142 8.25 4.55098 8.25 4.5C8.25 4.44902 8.2343 4.39858 8.20385 4.35172C8.17341 4.30487 8.12884 4.26258 8.07287 4.22741Z"
                  fill="#5F5F5F"
                />
              </svg>
            </foreignObject>
          </svg>
        </div>
      </button>
    );
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        type="button"
        className="sub-slick-prev-arrow sub-slick-arrow"
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 56 56"
          fill="none"
        >
          <circle cx="28" cy="28" r="28" fill="rgba(255, 255, 255, 0.6)" />
          <foreignObject x="20" y="20" width="32" height="32">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 12 14"
              fill="none"
            >
              <path
                d="M0.283406 6.56386L10.4666 0.166377C10.6365 0.0595777 10.8623 -4.74805e-07 11.097 -4.85066e-07C11.3318 -4.95327e-07 11.5575 0.0595776 11.7274 0.166377L11.7384 0.173606C11.821 0.22538 11.8869 0.2877 11.9318 0.356774C11.9768 0.425849 12 0.500235 12 0.575406C12 0.650577 11.9768 0.724963 11.9318 0.794038C11.8869 0.863113 11.821 0.925433 11.7384 0.977207L2.14902 7.00121L11.7384 13.0228C11.821 13.0746 11.8869 13.1369 11.9318 13.206C11.9768 13.275 12 13.3494 12 13.4246C12 13.4998 11.9768 13.5741 11.9318 13.6432C11.8869 13.7123 11.821 13.7746 11.7384 13.8264L11.7274 13.8336C11.5575 13.9404 11.3318 14 11.097 14C10.8623 14 10.6365 13.9404 10.4666 13.8336L0.283406 7.43614C0.193849 7.37988 0.122551 7.31221 0.0738357 7.23724C0.0251201 7.16227 3.09545e-07 7.08156 3.0598e-07 7C3.02415e-07 6.91844 0.0251201 6.83773 0.0738357 6.76276C0.122551 6.68779 0.193849 6.62012 0.283406 6.56386Z"
                fill="#525252"
              />
            </svg>
          </foreignObject>
        </svg>
      </button>
    );
  };

  const settings = {
    dots: true, // Enable dot indicators for navigation
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Initial autoplay state
    autoplaySpeed: 3500,
    nextArrow: <CustomNextArrow />, // Customized arrow buttons
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      sliderRef.current.slickPause(); // Pause autoplay
    } else {
      sliderRef.current.slickPlay(); // Start autoplay
    }
    setIsPlaying(!isPlaying);
  };

  const navigateToUrl = (url) => {
    navigate(url); // Use navigate function to change the URL
  };

  return (
    <div>
      {/* Slider */}
      <div className="sub-banner-container">
        <Slider ref={sliderRef} {...settings}>
          {subBannerImages.map((image, index) => (
            <div
              key={index}
              className="slider-image-container"
              onClick={() => navigateToUrl(image.navigateTo)}
            >
              <img
                src={image.url}
                alt={`Slide ${index}`}
                style={{
                  width: "364px",
                  height: "387px",
                  display: "block",
                  margin: "0 auto",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
          {/* Image mapping */}
        </Slider>
        {/* Pause/Play button */}
        <div className="sub-control-buttons">
          <button
            type="button"
            onClick={togglePlayPause}
            className="main-play-pause-btn"
          >
            {isPlaying ? (
              // Pause Icon in SVG
              <svg
                width="13px"
                height="13px"
                viewBox="0 0 24 24"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 5H10V19H6V5ZM14 5H18V19H14V5Z" />
              </svg>
            ) : (
              // Play Icon in SVG
              <svg
                width="13px"
                height="13px"
                viewBox="0 0 24 24"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubBanners;
