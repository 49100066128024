import { useState } from "react";

export default function SizeItem({
  item,
  index,
  handleSizeClick,
  handleQuantityChange,
  quantities,
  selectedItemIdx,
  isOutletStyle,
}) {
  const [focused, setFocused] = useState(false);

  const isActive = item.b2bActiveFlag === true;

  // Active item
  const activeStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "9px",
    flexBasis: "calc(23% - 1px)",
    border:
      selectedItemIdx === index
        ? "1px solid var(--Primary-color---Teal, #04ADD1)"
        : "1px solid #C3C3C3",
    backgroundColor: "white",
    padding: "10px 9px",
    margin: "5.5px",
  };

  const inputStyle = {
    width: "96px",
    height: "26px",
    border: focused
      ? "1px solid var(--Primary-color---Teal, #04ADD1)"
      : "1px solid #090909",
    background: focused ? "rgba(4, 173, 209, 0.05)" : "white",
  };

  // Inactive item
  const inactiveStyle = {
    ...activeStyle,
    backgroundColor: "#E2E2E2",
    border: "1px solid #E2E2E2",
  };

  const inactiveInputStyle = {
    ...inputStyle,
    display: "inline-flex",
    padding: "10px 9px",
    alignItems: "flex-start",
    gap: "10px",
    background: "#E2E2E2",
    border: "1px solid #FFFFFF",
    pointerEvents: "none",
  };

  // function to format date
  function formatDate(dateStr) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const date = new Date(dateStr);
    const year = date.getFullYear().toString().slice(-2); // Get only the last two digits of the years
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, "0"); // Ensure the day is two digits
    return `${month}/${day}/${year}`; // Format: "Mon-Day-Year"
  }

  return (
    <div
      style={isActive ? activeStyle : inactiveStyle}
      onClick={() => handleSizeClick(index)}
    >
      <div
        style={{
          color: isActive
            ? "var(--Primary-txt-Black, #0B0B0B)"
            : "var(--Base-White, #FFF)",
          fontFamily: "Lato",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "normal",
        }}
      >
        {item.itemSize}
      </div>

      <div
        style={{
          color: isActive
            ? "var(--Primary-color---Teal, #04ADD1)"
            : "var(--Base-White, #FFF)",
          fontFamily: "Lato",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "normal",
        }}
      >
        ${item.unitPrice}
      </div>
      <div
        style={{
          color: isActive
            ? "var(--Body-txt-Grey, #474747)"
            : "var(--Base-White, #FFF)",
          fontFamily: "Lato",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        }}
      >
        QTY: {item.qtyAvailable}
      </div>
      <input
        type="text"
        style={isActive ? inputStyle : inactiveInputStyle}
        value={quantities.find((q) => q.pid === item.pid)?.quantity || ""}
        onChange={(e) =>
          handleQuantityChange(
            e,
            item.pid,
            item.qtyAvailable,
            item.isPreorder,
            item.discountRate
          )
        }
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {item.nextAvailDate === "" ? (
        <div
          style={{
            display: "flex",
            width: "96px",
            padding: "5px",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
            borderRadius: "3px",
            background: "var(--Neutral-200, #F9F9F9)",
          }}
        >
          <div
            style={{
              color: isActive
                ? "var(--Body-txt-Grey, #474747)"
                : "rgba(71, 71, 71, 0.50)",
              fontFamily: "Lato",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            {isOutletStyle ? "WSL" : "ETA:TBD"}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "96px",
            padding: "5px",
            justifyContent: "center",
            alignItems: "center",
            gap: "3px",
            borderRadius: "3px",
            background: "var(--Neutral-200, #F9F9F9)",
          }}
        >
          <div
            style={{
              color: isActive
                ? "var(--Body-txt-Grey, #474747)"
                : "rgba(71, 71, 71, 0.50)",
              fontFamily: "Lato",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            {isOutletStyle ? "WSL" : `ETA:${formatDate(item.nextAvailDate)}`}
          </div>
        </div>
      )}
      {item.isPreorder === 1 && (
        <div style={{ color: "red", fontSize: "12px" }}>Pre-Order!</div>
      )}
    </div>
  );
}
