import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import Dialog from "@mui/material/Dialog";
import styled from "@emotion/styled";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  getCart,
  updateCart,
  removeItem,
  clearCart,
} from "../../actions/cartAction";
import InputQuantityCom from "../Helpers/InputQuantityCom";
import PrimaryButton from "../Helpers/Buttons/PrimaryButton";
import SecondaryButton from "../Helpers/Buttons/SecondaryButton";

export default function ProductsTable({
  className,
  cartItems,
  onItemRemoved,
  placeOrderPermission,
  updateLocalItems,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // alert for backorder
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const [quantity, setQuantity] = useState();
  const [userId, setUserId] = useState();
  const [Pid, setPid] = useState();
  const [parentId, setParentId] = useState();
  const [subTotal, setSubTotal] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [subSavedMoney, setSubSavedMoney] = useState({});
  const [totalSavedMoney, setTotalSavedMoney] = useState(0);

  const cartItemsArray = cartItems || [];
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);

  // Total number of items in cart
  useEffect(() => {
    const sumQty = cartItemsArray.reduce(
      (acc, currItem) => acc + currItem.qty,
      0
    );
    setTotalNumberOfItems(sumQty);

    console.log("cartItemArray", cartItemsArray);
  }, [cartItemsArray]);

  // Validate user
  const { search } = useLocation(); //search is gonna be "/Home"
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/Login"; // check if redirect url exist

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, userLoading, userError } = userSignin;

  // Update quantity
  const handleQuantityChange = (itemId, newQuantity) => {
    setQuantity(newQuantity);
  };

  // Calculate SubTotal Price with discount
  const calculateSubTotal = (unitPrice, discountRate, quantity) => {
    const discountPrice = unitPrice * (1 + discountRate / 100);
    const roundedDiscountPrice = parseFloat(discountPrice);
    const subTotal = roundedDiscountPrice * quantity;
    return parseFloat(subTotal.toFixed(2));
  };

  // Calculate Saved Money
  const calculateSavedMoneyPerItem = (
    calculatedSubTotal, //with discounted rate
    unitPrice,
    currentQty
  ) => {
    const unitSubTotal = unitPrice * currentQty;
    const unitSavedMoney = unitSubTotal - calculatedSubTotal;

    return parseFloat(unitSavedMoney.toFixed(2));
  };

  const calculateTotalPrice = () => {
    const totalPrice = cartItemsArray.reduce((accumulator, item) => {
      return accumulator + (subTotal[item.id] || item.discountedSubTotal);
    }, 0);

    const totalSavedPrice = cartItemsArray.reduce((accumulator, item) => {
      const priceDifference = item.subTotal - item.discountedSubTotal;

      return accumulator + (subSavedMoney[item.id] || priceDifference);
    }, 0);

    setTotalSavedMoney(totalSavedPrice);
    setTotalPrice(totalPrice);
  };

  // Calculate Total Price
  useEffect(() => {
    calculateTotalPrice();
  }, [cartItemsArray, subTotal]);

  // update qty
  const handleUpdateQty = (
    userid,
    productid,
    itemid,
    selectedQty,
    availableQuantity,
    inputQuantity,
    unitPrice,
    discountedItemPrice,
    parentid,
    discountRate
  ) => {
    console.log("selectedQty : ", selectedQty);
    console.log("availableQuantity : ", availableQuantity);
    console.log("inputQuantity : ", inputQuantity);

    if (inputQuantity > availableQuantity || selectedQty > availableQuantity) {
      setDialogMessage(
        "You can place backorders for any remaining quantities that exceed the current orderable quantity. If you prefer not to place a backorder, kindly adjust the quantity of the item in your order to match the currently available stock."
      );
      setDialogOpen(true);
    }

    setQuantity(inputQuantity);

    setUserId(userid);
    setPid(productid);
    setParentId(parentid);

    // Calculate the new subtotal and saved money per item
    const calculatedSubTotal = calculateSubTotal(
      unitPrice,
      discountRate,
      inputQuantity
    );

    const calculatedSavedMoneyPerItem = calculateSavedMoneyPerItem(
      calculatedSubTotal,
      unitPrice,
      inputQuantity
    );

    // Update the local state for subtotal and saved money
    setSubSavedMoney((prevSubSavedMoney) => ({
      ...prevSubSavedMoney,
      [itemid]: calculatedSavedMoneyPerItem,
    }));

    // Set subtotal price with discunted rate
    setSubTotal((prevSubTotal) => ({
      ...prevSubTotal,
      [itemid]: calculatedSubTotal,
    }));

    // Perform the cart update operation
    if ((userid && productid && quantity && parentid) || parentid === 0) {
      dispatch(
        updateCart({
          UserId: userid,
          Pid: productid,
          Quantity: inputQuantity,
          ParentId: parentid,
        })
      ).then(() => {
        // Assuming dispatch is properly set to return a promise
        // After successful update, you might want to fetch the updated cart
        // Or update the cart state directly if you have the new state available
        // Here, we'll simulate fetching the updated cart
        dispatch(getCart(userid))
          .then((updatedCartResponse) => {
            // Assuming the updated cart response is in the format you expect
            // And assuming `updateLocalItems` is a prop function passed down to update parent's state
            const updatedCartItems = updatedCartResponse.data.result; // Adjust based on actual response structure
            updateLocalItems(updatedCartItems);
          })
          .catch((error) => {
            console.error("Failed to update cart item quantity:", error);
            // Handle error, for example, by displaying a message to the user
          });
      });
    }
  };

  // Delete item
  const handleRemoveItem = async (userid, productid, parentid) => {
    try {
      await dispatch(
        removeItem({ UserId: userid, ProductId: productid, ParentId: parentid })
      );

      const updatedCartResponse = await dispatch(getCart(userid));
      const updatedCartItems = updatedCartResponse.payload.data.result;
      updateLocalItems(updatedCartItems);

      onItemRemoved(productid);
    } catch (error) {
      console.error("Failed to remove cart item:", error);
    }
  };
  /* const handleRemoveItem = (userid, productid, parentid) => {
    setUserId(userid);
    setPid(productid);
    setParentId(parentid);
    dispatch(
      removeItem({ UserId: userid, ProductId: productid, ParentId: parentid })
    );

    onItemRemoved(productid);
  };*/

  // Move to other page
  const goBackProductPage = (collection, styleNumber, selectedColor, fit) => {
    // Convert selectedColor to uppercase
    const uppercaseColor = selectedColor.toUpperCase();

    // Use the uppercaseColor in the navigate function
    navigate(`/${collection}/${styleNumber}/${uppercaseColor}/${fit}`);
  };

  // Continue shopping
  const goToShopping = () => {
    // navigate(-1);
    navigate("/");
  };

  // Go to checkout
  const goToCheckout = () => {
    navigate("/checkout");
  };

  // Clear cart
  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const StyledDialogTitle = styled(DialogTitle)`
    background-color: #ff4d00;
    color: white;
    text-align: center;
    font-weight: bold;
    padding: 1spx;
  `;

  const StyledDialogContent = styled(DialogContent)`
    text-align: center;
    padding: 30px;
  `;

  const StyledDialogActions = styled(DialogActions)`
    display: flex;
    justify-content: space-evenly;
    padding: 15px;
  `;

  return (
    <div className={`w-full ${className || ""}`}>
      <div className="page-title-wrapper w-full h-[100px] py-10">
        <div className="container-x mx-auto">
          <div className="flex items-center">
            <h1 className="shopping-cart-title">
              Shopping Cart - {totalNumberOfItems} item(s)
            </h1>
          </div>
        </div>
      </div>
      <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
          <tbody>
            {/* table heading */}
            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
              <td className="py-4 pl-10 block whitespace-nowrap min-w-[450px]">
                Product
              </td>
              {/* <td className="py-4 whitespace-nowrap text-center">Color</td> */}
              {/* <td className="py-4 whitespace-nowrap text-center">Size</td> */}
              <td className="py-4 whitespace-nowrap text-center w-[120px]">
                Price
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Quantity
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Sub Total
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Username
              </td>
              <td className="py-4 whitespace-nowrap  text-center w-[120px]">
                Admin
              </td>
              <td className="py-4 whitespace-nowrap text-right w-[80px]"></td>
            </tr>
            {/* table heading end */}
            {cartItemsArray &&
              cartItemsArray.map((item) => (
                <tr
                  key={item.id}
                  className="bg-white border-b hover:bg-gray-50"
                >
                  <td className="pl-10 py-4 w-[380px]">
                    <div className="flex space-x-6 items-center">
                      <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                        <img
                          src={`https://maevn-images.s3.us-east-2.amazonaws.com/110x110/${item.productLine.replace(
                            /\s+/g,
                            ""
                          )}/${item.styleNumber}${item.colorCode}.jpg`}
                          alt="product"
                          className="w-full h-full object-contain"
                          onError={(event) => {
                            event.target.onerror = null; // prevent infinite error loop
                            event.target.src = `https://maevn-images.s3.us-east-2.amazonaws.com/swatches/NoImage.jpg`; // set fallback image
                          }}
                        />
                      </div>
                      <div
                        className="flex-1 flex flex-col"
                        onClick={() =>
                          goBackProductPage(
                            item.productLine,
                            item.styleNumber,
                            item.colorCode,
                            item.simplifiedFit
                          )
                        }
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "-10px",
                        }}
                      >
                        <p className="font-medium text-[15px] text-qblack ">
                          Collection: {item.productLine}, Style Number:{" "}
                          {item.styleNumber}
                        </p>
                        <span className="font-medium text-[15px] text-qblack">
                          Fit: {item.fit} &nbsp;&nbsp;
                          {item.isPreorder ? (
                            <span className="text-qred">Pre-Order!</span>
                          ) : null}
                        </span>
                        <span className="text-[15px] font-normal">
                          Color: {item.color} &nbsp;|&nbsp;Size: {item.size}
                        </span>
                      </div>
                    </div>
                  </td>
                  {/* <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        {item.color}
                      </span>
                    </div>
                  </td>
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        {item.size}
                      </span>
                    </div>
                  </td> */}
                  {/* Item price with discount */}
                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      {item.discountRate < 0 ? (
                        <>
                          <span className="text-[15px] font-normal line-through text-gray-500 mr-2">
                            ${item.itemPrice}
                          </span>
                          <span className="text-[20px] font-normal text-blue-600">
                            ${item.discountedItemPrice}
                          </span>
                        </>
                      ) : (
                        <span className="text-[15px] font-normal">
                          ${item.itemPrice}
                        </span>
                      )}
                    </div>
                  </td>
                  <td className="py-4">
                    <div className="flex flex-col justify-center items-center">
                      <InputQuantityCom
                        key={item.id}
                        itemId={item.id}
                        quantity={item.qty}
                        availableQuantity={item.availableQty}
                        onQuantityChange={handleQuantityChange} // This updates `New quantity` state in parent component
                      />

                      <button
                        type="button"
                        className="text-xs font-semibold text-blue-600 mt-2 "
                        onClick={() =>
                          handleUpdateQty(
                            item.userId,
                            item.pid,
                            item.id,
                            item.qty,
                            item.availableQty, // Inventory
                            quantity, // This now refers to the potentially updated quantity
                            item.itemPrice,
                            item.discountedItemPrice,
                            item.parentAccountId,
                            item.discountRate
                          )
                        }
                      >
                        Update Qty
                      </button>
                    </div>
                  </td>

                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        ${subTotal[item.id] || item.discountedSubTotal}
                        {/* pid 대신 유니크키로 바꿀것 */}
                      </span>
                      {/*<span className="text-[15px] font-normal">
                        {subTotal[item.pid] ||
                          (item.discountRate > 0
                            ? parseFloat(
                                (
                                  item.itemPrice *
                                  (1 - item.discountRate / 100) *
                                  item.qty
                                ).toFixed(2)
                              )
                            : item.subTotal)}
                      </span>*/}
                    </div>
                  </td>

                  <td className="text-center py-4 px-2">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal text-red-500">
                        {item.userName}
                      </span>
                    </div>
                  </td>
                  <td className="text-right py-4">
                    <div className="flex space-x-1 items-center justify-center">
                      <span className="text-[15px] font-normal">
                        {item.parentAccountLoginId}
                      </span>
                    </div>
                  </td>

                  {/*Item remove button */}
                  <td className="text-right py-4">
                    <div className="flex space-x-1 items-center justify-center">
                      <button
                        type="button"
                        onClick={() =>
                          handleRemoveItem(
                            item.userId,
                            item.pid,
                            item.parentAccountId
                          )
                        }
                      >
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                            fill="#AAAAAA"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs" // Limit the width of the dialog
          fullWidth
          style={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "30px",
            gap: "10px",
            borderRadius: "10px",
            background: "var(--Base-White, #FFF)",
            margin: "30px", // 모달창의 바깥 간격
          }}
        >
          {/* <StyledDialogTitle id="alert-dialog-title">
                    Warning
                  </StyledDialogTitle>*/}
          <StyledDialogContent style={{ paddingTop: "0", paddingBottom: "5" }}>
            <DialogContentText id="alert-dialog-description">
              {dialogMessage}
            </DialogContentText>
          </StyledDialogContent>
          <StyledDialogActions
            style={{ marginTop: "30", justifyContent: "flex-end" }}
          >
            <button
              type="button"
              className="black-btn text-sm font-semibold w-1/4 h-10"
              onClick={() => setDialogOpen(false)}
            >
              Confirm
            </button>
          </StyledDialogActions>
        </Dialog>
      </div>

      <div className="w-full mt-[23px]">
        {/* indentation between layout*/}
        <div className="w-full sm:flex justify-between">
          {/*  <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
             <div className="flex-1 h-full">
              <InputCom type="text" placeholder="Discount Code" />
            </div>
            <button type="button" className="w-[90px] h-[50px] black-btn">
              <span className="text-sm font-semibold">Apply</span>
            </button>
          </div> */}
          <div className="flex space-x-2.5 items-center"></div>

          {/* <div
              className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center cursor-pointer"
              onClick={goToShopping}
            >
              <span className="text-sm font-semibold">Continue Shopping</span>
               </div> 
            <a href="/cart" onClick={() => handleClearCart()}>
              <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                <span className="text-sm font-semibold">Clear Cart</span>
              </div>
            </a>*/}
          <a href="/quickorder" onClick={() => handleClearCart()}>
            <div className="tertiaryButton2">
              <span className="text-sm font-semibold ">Clear All</span>
            </div>
          </a>
        </div>
        <div className="w-full mt-[30px] flex sm:justify-end">
          <div className="sm:w-[370px] w-full border border-[#EDEDED] px-[30px] py-[26px]">
            <div className="total mb-6">
              <div className=" flex justify-between">
                <p className="text-[14px] font-medium text-qblack">You saved</p>
                <p className="text-[14px] font-medium text-qred">
                  ${totalSavedMoney.toFixed(2)}
                </p>
              </div>
              <div className=" flex justify-between">
                <p className="text-[18px] font-medium text-qblack">Total</p>
                <p className="text-[18px] font-medium text-qred">
                  ${totalPrice.toFixed(2)}
                </p>
              </div>
            </div>

            {placeOrderPermission === 1 && (
              <div>
                <PrimaryButton
                  width="300px"
                  height="50px"
                  fontSize="18px"
                  onClick={goToCheckout}
                  style={{ marginBottom: "10px" }}
                >
                  Go To Checkout
                </PrimaryButton>
                {/* 
                <a
                  href="/checkout"
                  className="w-full h-[50px] black-btn flex justify-center items-center"
                >
                  <span className="text-sm font-semibold">Secure Checkout</span>
                </a> 
                */}
                <SecondaryButton
                  width="300px"
                  height="50px"
                  fontSize="18px"
                  onClick={goToShopping}
                >
                  Continue Shopping
                </SecondaryButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
